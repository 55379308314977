<template>
  <div role="main" class="main">
    <section
      class="common-page with-footer together_we_join image-page enlarge-page"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="border-l text-left">
              <p class="text-uppercase">
                Together we join you. We are here with you
              </p>
            </div>
          </div>
          <div class="col-12"  style="padding-top:30px">
            <p>
              Service will start shortly
            </p>
            <ul class="list-unstyled">
              <li v-for="visitor in visitors" :key="visitor.id">
                <a href="javascript:void(0);">
                  <img
                    :src="visitor.profile_url"
                    class="img-fluid br-50 fixed-image"
                  />
                </a>
                <br />
                <small> {{ visitor.full_name }}</small>
              </li>
            </ul>
          </div>
        </div>
        <!-- <transition name="pop" appear>
          <div
            class="modal fade show custom-modal"
            v-if="show_count_modal"
            id="Countdown"
            tabindex="-1"
            role="dialog"
            aria-labelledby="Countdown"
            aria-hidden="true"
            style="z-index: 9000"
          >
            <div
              class="modal-dialog modal-dialog-centered"
              style="width: 300px !important"
              role="document"
            >
              <div class="modal-content">
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-12 text-center">
                      <h5 class="text-custom my-4">
                        Stand by<br />for our<br />next speaker.
                      </h5>
                      <div class="countdown">
                        {{ count_number }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition> -->
      </div>
    </section>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import { apiBaseUrl, liveBaseUrl } from "../../constants/config";

import adapter from "webrtc-adapter";
window["adapter"] = adapter;

export default {
  data() {
    return {
      visitors: [],
      event_id: 0,
      connection: null,
      event: null,
      count_number: 10,
      show_count_modal: false,
    };
  },
  mounted() {
    this.event_id = JSON.parse(localStorage.getItem("loginInfo"))
      ? JSON.parse(localStorage.getItem("loginInfo")).event_id
      : 0;
    let ref = this;
    getApiManager()
      .post(`${apiBaseUrl}/api/visitor/all`, { event_id: this.event_id })
      .then((response) => {
        let res = response.data;
        if (res.status === true) {
          let detail = res.detail;
          ref.visitors = detail.visitors;
          for (let i = 0; i < ref.visitors.length; i++) {
            ref.visitors[i].profile_url =
              apiBaseUrl + "/" + ref.visitors[i].profile_url;
          }
          ref.event = detail.event;
          console.log("ooooiiii",this.event_id)
          if (ref.event.status == 0) {
            let randomnumber = Math.floor(Math.random() * 10000) + 1;
            this.$router.push("/visitor/dashboard/"+this.event_id+"?x=" + randomnumber);
          } else if (ref.event.status == 2) {
            let randomnumber = Math.floor(Math.random() * 10000) + 1;
            this.$router.push("/visitor/live/"+this.event_id+"?x=" + randomnumber);
          } else if (ref.event.status == 3) {
            let randomnumber = Math.floor(Math.random() * 10000) + 1;
            this.$router.push("/visitor/parting/"+this.event_id+"?x=" + randomnumber);
          }
        }
      })
      .catch((error) => {});

    this.connectSocket();
    this.countDownTimer();
    // this.checkLive = setInterval(() => {
    //   this.goLive()
    // }, 1000*30);
  },
  methods: {
    countDownTimer() {
      this.show_count_modal = true;
      if (this.count_number > 0) {
        setTimeout(() => {
          this.count_number -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.show_count_modal = false;
        let randomnumber = Math.floor(Math.random() * 10000) + 1;
        this.$router.push("/visitor/live/"+this.event_id+"?x=" + randomnumber);
      }
    },
    connectSocket() {
      var ref = this;

      this.connection = new WebSocket(liveBaseUrl);
      this.connection.onmessage = function (event) {
        //this.isLoading = false;
        let data = JSON.parse(event.data);
        if (data.type == "change_order") {
          console.log("WAITING");
          ref.receiveMoveLive(data.order);
        } else if (data.type == "user_info") {
          ref.connection.send(
            JSON.stringify({
              type: "my_info",
              event_id: ref.event_id,
            })
          );
        } else if (data.type == "end_live") {
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          ref.$router.push("/visitor/parting/"+data.event_id+"?x=" + randomnumber);
        }
      };

      this.connection.onclose = (e) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second."
        );
        setTimeout(() => {
          ref.connectSocket();
        }, 1000);
      };
    },
    receiveMoveLive(order) {
      this.connection.close();
      console.log("&&&&&&&&&&&&&WAITING");
      if (order == 1) {
        if (this.$route.fullPath != "/visitor/wait") {
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          this.$router.push("/visitor/wait/"+this.event_id+"?x=" + randomnumber);
        }
      } else if (order == 2) {
        if (this.$route.fullPath != "/visitor/live") {
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          this.$router.push("/visitor/live/"+this.event_id+"?x=" + randomnumber);
        }
      }
    },
    goLive() {
      let ref = this;
      getApiManager()
        .post(`${apiBaseUrl}/api/visitor/profile/get-event`, {
          event_id: this.event_id,
        })
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            this.connection.close();
            if (res.detail.status == 2) {
              clearInterval(ref.checkLive);
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/visitor/live/"+this.event_id+"?x=" + randomnumber);
            } else if (res.detail.status == 3) {
              clearInterval(ref.checkLive);
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/visitor/parting/"+this.event_id+"?x=" + randomnumber);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {},
  computed: {},
  watch: {},
};
</script>
